<template>
    <div class="article">
        <div class="title-label-box">
            <div :class="['label-box', index == selIdx?'sel-label-box':'']" v-for="(item,index) in labelList" :key="index" @Click="clickLabel(item.id, index)">
                {{ item.name }}
            </div>
        </div>
        <div class="text-body-box">
            <div class="label-url">首页><span @click="pageNum = 1">康养科普视频>{{ labelList[selIdx].name || "" }}</span></div>
            <div class="know-list-box">
                <div class="label-name-high"><img src="../assets/images/热点.png" style="width: 20px;height: 20px; margin-right: 5px;" /> 热门视频</div>
                <div class="list-body-box" v-if="knowList.length > 0">
                    <div class="know-list-row" v-for="(item, index) in knowList" :key="index">
                        <div class="left-video-img" @click="knowsMap = item,videoStatus = 1">
                            <video :src="item.content" style="width: 100%; height: 100%;"></video>
                            <div class="video-center-icon">
                                <el-icon><CaretRight /></el-icon>
                            </div>
                        </div>
                        <div class="right-text-box">
                            <div class="video-title">{{ item.title }}</div>
                            <div class="label-list-tag">
                                <div class="label-tag-box" v-for="val in item.labelList" :key="val.id">{{ val.name }}</div>
                            </div>
                            <div class="video-bottom-box">
                                <div>{{ item.copyTitle }}</div>
                                <div>{{ item.createTime }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="knowList.length == 0" style="width: 100%; height: 100%;">
                    <img src="../assets/images/无数据.png" style="width: 200px; height: 200px; margin-top: 50px;"/>
                </div>
                <div class="paging-box">
                    <el-pagination :hide-on-single-page="false" :page-size="size" :current-page="current" 
                        :page-sizes="[10,20,30,50,100]" background layout="prev, pager, next, jumper, total, sizes" :total="total" 
                        @current-change="handleCurrentChange" @size-change="handleSizeChange"/>
                </div>
            </div>
        </div>

        <!-- 打开视频播放器 -->
        <div v-if="videoStatus == 1" class="open-screen" @click="videoStatus = 0">
            <video :src="knowsMap.content" controls autoplay style="width: 60%; height: 60%;"></video>
        </div>
    </div>
</template>

<script>
import api from '@/api/commonApi'

export default {
    data() {
        return {
            selIdx: 0,
            labelList: [],
            knowList: [],
            current: 1,
            size: 10,
            total: 0,
            knowsMap: {},
            pageNum: 1,
            videoStatus: 0,
        }
    },
    created() {
        this.getLabelList()
    },
    methods: {
        // 获取标签
        getLabelList() {
            api.libelList({ status: 0 }).then(res => {
                if(res) {
                    this.labelList = res
                    this.getKnowList(res[this.selIdx].id)
                }
            })
        },
        // 获取文章
        getKnowList(labelId) {
            api.knowByLabelId({
                current: this.current,
                size: this.size,
                labelId: labelId,
                type: 2
            }).then(res => {
                if(res) {
                    this.knowList = res.records
                    this.total = res.total
                }
            })
        },
        // 切换标签
        clickLabel(labelId, selIdx) {
            if(this.selIdx != selIdx) {
                this.current = 1
                this.getKnowList(labelId)
                this.selIdx = selIdx
            }
            this.pageNum = 1
        },
        // 切换页数
        handleCurrentChange(e) {
            this.current = e
            this.getKnowList(this.labelList[this.selIdx].id)
        },
        // 切换页数大小
        handleSizeChange(e) {
            this.size = e
            this.getKnowList(this.labelList[this.selIdx].id)
        },
    }
}
</script>

<style lang="less">
.article {
    width: auto;
    min-height: 800px;

    .title-label-box {
        width: auto;
        height: 60px;
        line-height: 60px;
        margin: 0 18%;
        display: flex;
        align-items: center;
        border-bottom: 2px solid #D2D2D3;

        .label-box{
            width: auto;
            padding: 0 4%;
            font-size: 18px;
            font-weight: bold;
            white-space: nowrap;
        }
    }

    .text-body-box {
        width: auto;
        margin: 0 18%;
        display: flex;
        flex-direction: column;

        .know-list-box {
            width: 100%;
            min-height: 600px;
            position: relative;
            padding-bottom: 90px;

            .paging-box {
                width: 100%;
                position: absolute;
                bottom: 30px;
            }

            .label-name-high {
                width: auto;
                padding: 5px 10px;
                text-align: left;
                height: 30px;
                font-size: 22px;
                font-weight: bold;
                background: #EAEFF2;
            }

            .list-body-box {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                padding: 40px 0;

                .know-list-row {
                    width: 45%;
                    height: 140px;
                    margin-bottom: 40px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    .left-video-img {
                        width: 42%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.2);
                        position: relative;

                        .video-center-icon{
                            width: 30px;
                            height: 30px;
                            border-radius: 30px;
                            color: #ffffff;
                            background: #16B82E;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: absolute;
                            top: 40%;
                            left: 45%;
                            z-index: 9999;
                        }
                    }

                    .right-text-box {
                        width: 55%;
                        height: 100%;
                        padding-left: 15px;
                        padding-right: 10px;
                        position: relative;
                        box-shadow: 4px 4px 8px #D2D2D3;

                        .video-title {
                            width: auto;
                            text-align: left;
                            margin-bottom: 25px;
                            padding-top: 5px;
                            color: #000000;
                        }

                        .label-list-tag {
                            width: auto;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;

                            .label-tag-box {
                                width: auto;
                                height: 18px;
                                border-radius: 3px;
                                padding: 3px 5px;
                                font-size: 12px;
                                margin-right: 10px;
                                margin-bottom: 5px;
                                border: 1px solid #16B82E;
                            }
                        }

                        .video-bottom-box {
                            width: calc(100% - 20px);
                            height: 20px;
                            font-size: 12px;
                            color: #979BA2;
                            line-height: 20px;
                            position: absolute;
                            bottom: 10px;
                            display: flex;
                            justify-content: space-between;
                        }
                    }
                }

            }
            
        }
    }
}

.html-box {
    width: 100%;
    text-align: left;
}


.sel-label-box{
    border-bottom: 2px solid #16B82E;
}

.label-url{
    font-size: 16px;
    color: #A5A5AA;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: left;
}

.open-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}
</style>./Article.vue