<template>
    <div style="margin-top: -10px;">
        <!-- 轮播图 -->
        <div class="banner-box">
            <el-carousel height="100%" v-if="imgList.length" autoplay style="width: 100%; height: 100%;" :pause-on-hover="false"
                indicator-position="none">
                <el-carousel-item style="height: 667px" v-for="(item, index) in imgList" :key="index">
                <img :src="item.imgUrl" style="width: 100%; height: 100%;" />
                </el-carousel-item>
            </el-carousel>
        </div>
    
        <div class="lookback">
            <!-- 内容 -->
            <div class="body-data-box">
                <div class="body-title">
                    <div style="width: 35px; height: 35px;"><img src="../assets/images/标题元素.jpg" style="width: 100%; height: 100%;" /></div>
                    <div style="font-size: 28px; font-weight: bold; margin: 0 20px; letter-spacing: 2px;">展览回顾</div>
                    <div style="width: 35px; height: 35px;"><img src="../assets/images/标题元素.jpg" style="width: 100%; height: 100%; transform: rotate(180deg);" /></div>
                </div>
                <div class="view-body-img">
                    <div class="child-box" style="top: 20vh; left: 10vw;" v-if="labelList.length > 0">
                        <div class="back-box" :style="{'background': labelList[0].background}"></div>
                        <div class="label-name">{{ labelList[0].name }}</div>
                        <div class="label-text" v-html="labelList[0].centent"></div>
                    </div>
                    <div class="child-box" style="top: 55vh; left: 17vw;" v-if="labelList.length > 1">
                        <div class="back-box" :style="{'background': labelList[1].background}"></div>
                        <div class="label-name">{{ labelList[1].name }}</div>
                        <div class="label-text" v-html="labelList[1].centent"></div>
                    </div>
                    <div class="child-box" style="top: 55vh; right: 17vw;" v-if="labelList.length > 2">
                        <div class="back-box" :style="{'background': labelList[2].background}"></div>
                        <div class="label-name">{{ labelList[2].name }}</div>
                        <div class="label-text" v-html="labelList[2].centent"></div>
                    </div>
                    <div class="child-box" style="top: 20vh; right: 10vw;" v-if="labelList.length > 3">
                        <div class="back-box" :style="{'background': labelList[3].background}"></div>
                        <div class="label-name">{{ labelList[3].name }}</div>
                        <div class="label-text" v-html="labelList[3].centent"></div>
                    </div>
                </div>
                <div class="body-title" style="margin-top: 60px;">
                    <div style="width: 35px; height: 35px;"><img src="../assets/images/标题元素.jpg" style="width: 100%; height: 100%;" /></div>
                    <div style="font-size: 28px; font-weight: bold; margin: 0 20px; letter-spacing: 2px;">展览回顾</div>
                    <div style="width: 35px; height: 35px;"><img src="../assets/images/标题元素.jpg" style="width: 100%; height: 100%; transform: rotate(180deg);" /></div>
                </div>
                <div class="img-list-box">
                    <div class="img-row-box" v-for="(item,index) in backList" :key="index">
                        <div class="img-back-box">
                            <img src="../assets/images/展览回顾背景.png" style="width: 100%; height: 100%;" />
                            <div class="title-name-box">
                                <div class="index-text"><span v-if="index < 9">0</span>{{ index + 1 }}</div>
                                <div class="title-name">{{ item.title }}</div>
                            </div>
                        </div>
                        <div class="body-img-list">
                            <div class="content-row-box" v-for="val in item.imgUrl.split(',')" :key="val">
                                <img :src="val" style="width: 100%; height: 100%; border-radius: 10px;" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 报名 -->
            <div class="apply-box">
                <div class="apply-title-box">
                    <div class="line-box"></div>
                    <div class="line-text">报名立即参与</div>
                    <div class="line-box"></div>
                </div>
                <div class="apply-body-box">
                    <div class="body-left-box">
                        <div class="left-server-box">
                            <img src="../assets/images/在线客服.png" style="width: 100%; height: 100%;" />
                        </div>
                        <div class="left-qrcode-box">
                            <img src="../assets/images/qrcode.png" style="width: 100%; height: 100%;" />
                        </div>
                    </div>
                    <div class="body-right-box">
                        <el-row :gutter="50">
                            <el-col :span="12">
                                <div class="input-text-box">
                                    <div class="input-label">您的姓名:</div>
                                    <input v-model="form.userName" style="width: 100%; height: 95%; border: none; background: #E8E8E8" />
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <div class="input-text-box">
                                    <div class="input-label">您的电话:</div>
                                    <input v-model="form.phone" style="width: 100%; height: 95%; border: none; background: #E8E8E8; outline: none;"
                                        maxlength="11"  oninput ="value=value.replace(/[^0-9]/g,'')"
                                        
                                    />
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <div class="input-text-box" style="background: #ffffff;">
                                    <div class="input-label">预约日期:</div>
                                    <el-date-picker
                                        v-model="form.reserveDate"
                                        type="date"
                                        placeholder="选择预约日期"
                                        value-format="YYYY-MM-DD"
                                    />
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <div class="input-text-box" style="background: #ffffff;">
                                    <div class="input-label">性别:</div>
                                    <el-radio-group v-model="form.gender" class="ml-4">
                                        <el-radio :label="1" size="large">男</el-radio>
                                        <el-radio :label="2" size="large">女</el-radio>
                                    </el-radio-group>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <div class="input-text-box">
                                    <div class="input-label">身份证号:</div>
                                    <input v-model="form.idCard" style="width: 100%; height: 95%; border: none; background: #E8E8E8" />
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <div class="input-text-box">
                                    <div class="input-label">您的单位:</div>
                                    <input v-model="form.unit" style="width: 100%; height: 95%; border: none; background: #E8E8E8" />
                                </div>
                            </el-col>
                            <el-col :span="24">
                                <div class="input-text-textarea" >
                                    <div class="input-label">备注</div>
                                    <textarea v-model="form.remark" type="textarea" :rows="6" style="width: 100%; border: none; background: #E8E8E8;" />
                                </div>
                            </el-col>
                            <el-col :span="24">
                                <div class="submit-box" @click="submitApply">立即报名</div>
                            </el-col>
                            <el-col :span="24">
                                <div style="margin-top: 20px; font-size: 12px; text-align: center;">请一定填写您的真实信息，以便我们的工作人员及时的联系到您！平台会对您所省的个人信息进行保密，请放心填写！</div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import api from '@/api/commonApi'
import { ElMessage } from 'element-plus';

export default {
    data() {
        return {
            imgList: [],
            backList: [],
            // 报名信息
            form: {
                userName: '',
                phone: '',
                idCard: '',
                unit: '',
                gender: 1,
                reserveDate: '',
                remark: '',
            },
            // 节流防抖
            isSubmit: true,
            // 标签集合
            labelList: [],
        }
    },
    created() {
        this.getList()
        this.getBannerImgs()
        this.getLookLabel()
    },
    methods: {
        // 获取标签
        getLookLabel() {
            api.lookLabelList({status: 0}).then(res => {
                if(res) {
                    this.labelList = res
                }
            })
        },
        // 获取轮播图
        getBannerImgs() {
            api.bannerList({ status: 0 }).then(res => {
                if (res) {
                this.imgList = res
                }
            })
        },
        getList() {
            api.backInfoList({status: 0}).then(res => {
                if(res) {
                    this.backList = res
                }
            })
        },
        // 会展预约
        submitApply() {
            if(this.form.userName == '' || this.form.phone == '' || this.form.reserveDate == ''){
                ElMessage.warning("请填写姓名、手机号、预约日期")
            }else {
                if(this.isSubmit) {
                    this.isSubmit = false
                    api.reserveInfoSave(this.form).then(res => {
                        if(res) {
                            ElMessage.success("预约成功")
                            this.form = {
                                userName: '',
                                phone: '',
                                idCard: '',
                                unit: '',
                                gender: 1,
                                reserveDate: '',
                                remark: '',
                            }
                        }
                        setTimeout(() => {
                            this.isSubmit = true
                        }, 3000);
                    })
                }
            }
        }
    }
}
</script>


<style lang="less" scoped>

.banner-box {
    width: 100%;
    height: 667px;
}

.lookback {
    width: auto;
    min-height: 50vh;
    margin: 0 10%;
    margin-top: 50px;


    .body-data-box {
        width: auto;
        margin: 20px 120px;

        .body-title {
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: center;
        }

        .view-body-img {
            width: auto;
            height: 90vh;
            margin-top: 20px;
            background: url('../assets/images/4颜色背景.png') no-repeat top center;
            background-size: cover;
            position: relative;

            .child-box {
                width: 190px;
                height: 190px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: absolute;

                .back-box {
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                    transform: rotate(45deg);
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 9;
                }

                .label-name {
                    width: 70%;
                    font-size: 18px;
                    letter-spacing: normal;
                    font-weight: bold;
                    z-index: 99;
                    text-align: center;
                }

                .label-text {
                    width: 70%;
                    text-align: center;
                    font-size: 12px;
                    line-height: 22px;
                    white-space: pre-line;
                    z-index: 99;
                }
            }
        }

        .img-list-box {
            width: 100%;
            margin-top: 20px;
            min-height: 400px;
            display: flex;
            flex-direction: column;


            .img-row-box {
                width: 100%;
                min-height: 120px;
                margin-bottom: 20px;
                position: relative;

                .body-img-list {
                    width: 64.2%;
                    margin-left: 23.8%;
                    min-height: 140px;
                    display: flex;
                    flex-direction: row;
                    // justify-content: space-between;
                    align-content: flex-start;
                    flex-wrap: wrap;
                    position: relative;
                    z-index: 99;

                    .content-row-box {
                        width: 30%;
                        height: 140px;
                        margin-right: 3%;
                        margin-bottom: 20px;
                    }
                }

                .img-back-box {
                    width: 100%;
                    height: 140px;
                    position: absolute;
                    display: flex;
                    z-index: 9;

                    

                    .title-name-box {
                        position: absolute;
                        width: 23.8%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        top: 0;
                        left: 0;

                        .index-text {
                            font-family: STLiti;
                            font-size: 72px;
                            line-height: 100px;
                            font-weight: bold;
                            color: #ffffff;
                            margin-left: 80px;
                            border-right: 1px solid #ffffff;
                        }

                        .title-name {
                            font-size: 16px;
                            font-weight: bold;
                            width: 12px;
                            margin-left: 10px;
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }


    .apply-box {
        width: auto;
        margin: 20px 120px;
        display: flex;
        flex-direction: column;
        margin-top: 50px;

        .apply-title-box {
            width: 100%;
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .line-box {
                width: 35%;
                height: 5px;
                background: #32E029;
            }

            .line-text {
                font-family: 'MingLiU';
                font-size: 24px;
                font-weight: bold;
                color: #000000;
                margin: 0 30px;
            }
        }

        .apply-body-box {
            width: 100%;
            display: flex;
            flex-direction: row;

            .body-left-box {
                width: 28%;
                display: flex;
                padding: 20px 0;
                flex-direction: column;
                align-items: center;
                border: 1px solid #E6E7E8;


                .left-server-box {
                    width: 40%;
                    height: auto;
                }

                .left-qrcode-box {
                    width: 60%;
                    height: auto;
                    margin-top: 20px;
                    border: 5px solid #16B82E;
                }
            }

            .body-right-box {
                width: 70%;
                margin-left: 2%;
                padding: 30px 20px;
                display: flex;
                justify-content: center;
                align-items: start;
                border: 1px solid #E6E7E8;

                .submit-box {
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    background: #16B82E;
                    color: #ffffff;
                    font-size: 18px;
                    text-align: center;
                    letter-spacing: 3px;
                    border-radius: 3px;
                    cursor: pointer;
                }

                .input-text-box {
                    width: auto;
                    height: 40px;
                    line-height: 40px;
                    padding-left: 10px;
                    display: flex;
                    align-items: center;
                    background: #E8E8E8;
                    margin-bottom: 30px;
                    border-radius: 5px;

                    .input-label{
                        white-space: nowrap;
                        margin-right: 15px;
                    }
                }

                .input-text-textarea {
                    width: auto;
                    min-height: 200px;
                    display: flex;
                    background: #E8E8E8;
                    margin-bottom: 30px;
                    border-radius: 5px;
                    padding-left: 10px;
                    line-height: 30px;

                    .input-label{
                        white-space: nowrap;
                        margin-right: 15px;
                    }
                }
            }
        }
    }
}
</style>