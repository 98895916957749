<template>
  <div class="about">
    <!-- 轮播图 -->
    <div class="banner-box">
      <el-carousel height="100%" v-if="imgList.length" autoplay style="width: 100%; height: 100%;" :pause-on-hover="false"
        indicator-position="none">
        <el-carousel-item style="height: 667px" v-for="(item, index) in imgList" :key="index">
          <img :src="item.imgUrl" style="width: 100%; height: 100%;" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 内容 -->
    <div class="body-box">
      <div class="body-title">
        <div style="width: 35px; height: 35px;"><img src="../assets/images/标题元素.jpg" style="width: 100%; height: 100%;" /></div>
        <div style="font-size: 28px; font-weight: bold; margin: 0 20px;">{{ lifeScienceMap.intro }}</div>
        <div style="width: 35px; height: 35px;"><img src="../assets/images/标题元素.jpg" style="width: 100%; height: 100%; transform: rotate(180deg);" /></div>
      </div>
      <div class="html-box">
        <div v-html="lifeScienceMap.content" ></div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/commonApi'

export default {
  data() {
    return {
      imgList: [],
      lifeScienceMap: {},
    }
  },
  created() {
    this.getBannerImgs()
    this.getLifeScience()
  },
  methods: {
    // 获取轮播图
    getBannerImgs() {
      api.bannerList({ status: 0 }).then(res => {
        if (res) {
          this.imgList = res
        }
      })
    },
    // 获取生命科学馆内容
    getLifeScience() {
      api.lifeScience({status: 0}).then(res => {
        if(res) {
          this.lifeScienceMap = res[0]
        }
      })
    },
  },
}

</script>

<style lang="less" scoped>
.about {
  width: 100%;
  margin-top: -10px;

  .banner-box {
    width: 100%;
    height: 667px;
  }

  .body-box {
    width: auto;
    min-height: 40vh;
    margin: 0 15%;

    .body-title {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      margin-top: 50px;
      margin-bottom: 20px;
    }

    .html-box {
      width: 70%;
      margin: 0 15%;
      padding: 0 20px;
    }
  }
}
</style>