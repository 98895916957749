<template>
    <div class="news">
        <!-- 轮播图 -->
        <div class="banner-box">
            <el-carousel height="100%" v-if="imgList.length" autoplay style="width: 100%; height: 100%;" :pause-on-hover="false"
                indicator-position="none">
                <el-carousel-item style="height: 667px" v-for="(item, index) in imgList" :key="index">
                    <img :src="item.imgUrl" style="width: 100%; height: 100%;" />
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="news-body">
            <div class="news-left">
                <el-collapse v-model="activeName" accordion @change="handleChange">
                    <el-collapse-item name="0" style="border-bottom: 1px solid #ebeef5;">
                        <template #title>
                            <div class="name-title" style="background: #088006;" @Click="openNews(0), viewType = 0">
                                <div style="font-size: 18px">活动报名</div>
                                <div
                                    style="width: 30px; height: 30px; border-radius: 30px; font-size: 24px; line-height: 30px; text-align: center; background: #ffffff; color: rgba(0,0,0,0.8);">
                                    <el-icon>
                                        <ArrowRightBold />
                                    </el-icon>
                                </div>
                            </div>
                        </template>
                        <div class="news-row-box" v-for="(item, index) in allNewsList" :key="index"
                            @click="getNewsInfo(item.id), openApply = 1">
                            <div class="left-text">{{ item.activityName }}</div>
                            <div class="right-icon">
                                <el-icon>
                                    <ArrowRightBold />
                                </el-icon>
                            </div>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="1">
                        <template #title>
                            <div class="name-title" style="background: #088006;" @Click="openNews(1)">
                                <div style="font-size: 18px">往期活动</div>
                                <div
                                    style="width: 30px; height: 30px; border-radius: 30px; font-size: 24px; line-height: 30px; text-align: center; background: #ffffff; color: rgba(0,0,0,0.8);">
                                    <el-icon>
                                        <ArrowRightBold />
                                    </el-icon>
                                </div>
                            </div>
                        </template>
                        <div class="news-row-box" v-for="(item, index) in allNewsList" :key="index"
                            @click="getNewsInfo(item.id)">
                            <div class="left-text">{{ item.activityName }}</div>
                            <div class="right-icon">
                                <el-icon>
                                    <ArrowRightBold />
                                </el-icon>
                            </div>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
            <div class="news-right">
                <div class="title-box">
                    <div class="body-top-left">科普活动</div>
                    <div class="body-top-right">当前位置：首页>科普活动>{{ type == '0' ? '活动报名' : '往期活动' }}</div>
                </div>
                <!-- 报名列表 -->
                <div v-if="viewType == 0 && type == 0" class="list-box">
                    <div class="news-centent-info" v-for="(item, index) in newsList" :key="index"
                        @Click="getNewsInfo(item.id)" style="cursor:pointer;">
                        <div class="left-text-box">
                            <div class="activity-title">{{ item.activityName }}</div>
                            <div class="activity-info">{{ item.introduce + item.introduce + item.introduce }}</div>
                            <div class="activity-time">活动时间：{{ item.startDate }}~{{ item.endDate }}</div>
                        </div>
                        <div class="right-image-box">
                            <img :src="item.viewImg" style="width: 100%; height: 100%; border-radius: 10px;" />
                        </div>
                    </div>
                    <div class="paging-box">
                        <el-pagination :hide-on-single-page="false" :page-size="size" :current-page="current"
                            :page-sizes="[5, 10, 30, 50, 100]" background
                            layout="prev, pager, next, jumper, total, sizes" :total="total"
                            @current-change="handleCurrentChange" @size-change="handleSizeChange" />
                    </div>
                </div>
                <!-- 往期活动 -->
                <div v-if="viewType == 0 && type == 1" class="list-box">
                    <div class="news-centent-info" v-for="(item, index) in newsList" :key="index"
                        @Click="getNewsInfo(item.id)" style="cursor:pointer;">
                        <div class="left-text-box">
                            <div class="activity-title">{{ item.activityName }}</div>
                            <div class="activity-info">{{ item.introduce }}</div>
                            <div class="activity-time" style="color: red;">活动已过期：{{ item.startDate }} ~ {{ item.endDate
                                }}</div>
                        </div>
                        <div class="right-image-box">
                            <img :src="item.viewImg" style="width: 100%; height: 100%; border-radius: 10px;" />
                        </div>
                    </div>
                    <div class="paging-box">
                        <el-pagination :hide-on-single-page="false" :page-size="size" :current-page="current"
                            :page-sizes="[5, 10, 30, 50, 100]" background
                            layout="prev, pager, next, jumper, total, sizes" :total="total"
                            @current-change="handleCurrentChange" @size-change="handleSizeChange" />
                    </div>
                </div>


                <!-- 过期活动详情-->
                <div v-if="viewType == 1" class="news-body-box">
                    <div class="body-title-box">
                        <div class="text-title">{{ newsMap.activityName }}</div>
                        <div class="text-body-box">
                            <div class="text-time">活动简介：{{ newsMap.introduce }}</div>
                        </div>
                    </div>
                    <div
                        style="margin-top: 25px; width: auto; height: 1px; margin: 0 20px; border-bottom: 1px dashed #666;">
                    </div>
                    <div class="text-body-data">
                        <div v-if="newsMap.viewType == 1" v-html="newsMap.content"></div>
                        <video v-if="newsMap.viewType == 2" :src="newsMap.content" controls
                            style="width: 100%; height: 100%;"></video>
                    </div>
                </div>
                <!-- 链接 -->
                <div v-if="viewType == 2" class="news-body-box" ref="iframeRef">
                    <UrlPage :url="newsMap.content" :scrollWidth="scrollWidth" :scrollwHeight="scrollHeight"></UrlPage>
                </div>


            </div>
        </div>
        <!-- 报名 -->
        <div v-if="type == 0 && viewType > 0" class="apply-box">
            <div class="apply-title-box">
                <div class="line-box"></div>
                <div class="line-text">报名立即参与</div>
                <div class="line-box"></div>
            </div>
            <div class="apply-body-box">
                <div class="body-left-box">
                    <div class="left-server-box">
                        <img src="../assets/images/在线客服.png" style="width: 100%; height: 100%;" />
                    </div>
                    <div class="left-qrcode-box">
                        <img src="../assets/images/qrcode.png" style="width: 100%; height: 100%;" />
                    </div>
                </div>
                <div class="body-right-box">
                    <el-row :gutter="50">
                        <el-col :span="12">
                            <div class="input-text-box">
                                <div class="input-label">您的姓名:</div>
                                <input v-model="form.name" style="width: 100%; height: 95%; border: none; background: #E8E8E8" />
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="input-text-box">
                                <div class="input-label">您的电话:</div>
                                <input v-model="form.phone" maxlength="11"  oninput ="value=value.replace(/[^0-9]/g,'')" style="width: 100%; height: 95%; border: none; background: #E8E8E8; outline: none;" />
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="input-text-box">
                                <div class="input-label">性别:</div>
                                <el-radio-group v-model="form.gender" class="ml-4">
                                    <el-radio :label="1" size="large">男</el-radio>
                                    <el-radio :label="2" size="large">女</el-radio>
                                </el-radio-group>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="input-text-box">
                                <div class="input-label">身份证号:</div>
                                <input v-model="form.idCard" style="width: 100%; height: 95%; border: none; background: #E8E8E8" />
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="input-text-box">
                                <div class="input-label">您的单位:</div>
                                <input v-model="form.unit" style="width: 100%; height: 95%; border: none; background: #E8E8E8" />
                            </div>
                        </el-col>
                        <el-col :span="24">
                            <div class="input-text-textarea" >
                                <div class="input-label">备注</div>
                                <textarea v-model="form.remark" type="textarea" :rows="6" style="width: 100%; border: none; background: #E8E8E8;" />
                            </div>
                        </el-col>
                        <el-col :span="24">
                            <div class="submit-box" @click="submitApply">立即报名</div>
                        </el-col>
                        <el-col :span="24">
                            <div style="margin-top: 20px; font-size: 12px; text-align: center;">请一定填写您的真实信息，以便我们的工作人员及时的联系到您！平台会对您所省的个人信息进行保密，请放心填写！</div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api/commonApi'
import UrlPage from '@/components/UrlPage.vue'
import { ElMessage } from 'element-plus';

export default {
    components: {
        UrlPage
    },
    data() {
        return {
            type: 0,
            newsId: null,
            viewType: 0,
            newsMap: {},
            openIdx: 0,
            activeName: '0',
            imgList: [],
            newsList: [],
            allNewsList: [],
            current: 1,
            size: 5,
            total: 0,
            scrollWidth: '100%',
            scrollHeight: '600',
            // 打开报名弹窗
            openApply: 0,
            // 报名信息
            form: {
                name: '',
                phone: '',
                idCard: '',
                unit: '',
                gender: 1,
                remark: '',
            },
            // 节流防抖
            isSubmit: true,
        }
    },
    watch: {
        '$route.query.type': function (newId, oldId) {
            console.log(newId, oldId);
            if (newId != oldId) {
                this.type = newId
                this.getAllNewsList()
                this.getNewsList({type: newId})
                this.activeName = newId + ''

            }
        }
    },
    created() {
        let val = this.$route.query
        if (val) {
            if (val.type) {
                this.type = val.type
                this.activeName = val.type
            }
            if (val.id) {
                this.newsId = val.id
                this.getNewsInfo(val.id)
            }
        }
        this.getBannerImgs()
        this.getNewsList(this.$route.query)
        this.getAllNewsList()
    },
    methods: {
        // 获取列表数据
        getNewsList(val) {
            api.activeList({ current: this.current, size: this.size, selType: val.type ? val.type : 0, status: 1, viewState: 0 }).then(res => {
                if (res) {
                    this.newsList = res.records
                    this.total = res.total
                }
            })
        },
        // 获取详情
        getNewsInfo(id) {
            api.activityInfo(id).then(res => {
                if (res) {
                    this.viewType = res.viewType == 3 ? 2 : 1
                    if (res.enteringType == 1) {
                        console.log(this.$refs.iframeRef.offsetWidth, this.$refs.iframeRef.offsetHeight);
                        this.scrollWidth = this.$refs.iframeRef.offsetWidth
                        this.scrollHeight = this.$refs.iframeRef.offsetHeight
                    }
                    this.newsMap = res
                    this.allNewsList.forEach((val, index) => {
                        if (val.id == res.id) {
                            return this.openIdx = index
                        }
                    });
                }
            })
        },
        // 获取轮播图
        getBannerImgs() {
            api.bannerList({ status: 0 }).then(res => {
                if (res) {
                    this.imgList = res
                }
            })
        },
        // 开关手风琴
        handleChange(e) {
            this.activeName = e
        },
        getAllNewsList() {
            let type = this.$route.query.type ? this.$route.query.type : 0
            api.activeList({ selType: type ? type : 0, status: 1, viewState: 0 }).then(res => {
                if (res) {
                    this.allNewsList = res
                }
            })
        },
        // 打开手风琴
        openNews(type) {
            if (this.activeName != null) {
                this.type = type
                api.activeList({ selType: type, status: 1, viewState: 0 }).then(res => {
                    if (res) {
                        this.allNewsList = res
                    }
                })
                this.getNewsList({ type: type })
            }
        },
        // 切换页数
        handleCurrentChange(e) {
            this.current = e
            this.getNewsList({ type: this.type })
        },
        // 切换页数大小
        handleSizeChange(e) {
            this.size = e
            this.getNewsList({ type: this.type })
        },
        // 上/下一篇
        goNextDoc(type) {
            if (type == 1) {
                this.openIdx = this.openIdx == 0 ? this.allNewsList.length - 1 : this.openIdx - 1
                this.newsMap = this.allNewsList[this.openIdx]
                this.viewType = 1

            } else {

                this.openIdx = this.openIdx == this.allNewsList.length - 1 ? 0 : this.openIdx + 1
                this.newsMap = this.allNewsList[this.openIdx]
                this.viewType = 1
            }
        },
        // 活动报名
        submitApply() {
            if(this.form.name == '' || this.form.phone =='') {
                ElMessage.warning("请填写姓名与手机号")
            }else{
                if(this.isSubmit) {
                    this.isSubmit = false
                    this.form.activityId = this.newsMap.id
                    api.actApplySave(this.form).then(res => {
                        if(res) {
                            ElMessage.success("报名成功")
                            setTimeout(() => {
                                this.form = {
                                    name: '',
                                    phone: '',
                                    idCard: '',
                                    unit: '',
                                    gender: 1,
                                    remark: '',
                                }
                                this.isSubmit = true
                            }, 3000)
                        }
                    })
                    setTimeout(() => {
                        this.isSubmit = true
                    }, 4000)
                }
            }
        }
    }
}

</script>

<style lang="less" scoped>
.news {
    width: 100%;
    margin-top: -10px;

    .apply-box {
        width: auto;
        margin: 20px 120px;
        display: flex;
        flex-direction: column;
        margin-top: 40px;

        .apply-title-box {
            width: 100%;
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .line-box {
                width: 35%;
                height: 5px;
                background: #32E029;
            }

            .line-text {
                font-family: 'MingLiU';
                font-size: 24px;
                font-weight: bold;
                color: #000000;
                margin: 0 30px;
            }
        }

        .apply-body-box {
            width: 100%;
            display: flex;
            flex-direction: row;

            .body-left-box {
                width: 28%;
                display: flex;
                padding: 20px 0;
                flex-direction: column;
                align-items: center;
                border: 1px solid #E6E7E8;


                .left-server-box {
                    width: 40%;
                    height: auto;
                }

                .left-qrcode-box {
                    width: 60%;
                    height: auto;
                    margin-top: 20px;
                    border: 5px solid #16B82E;
                }
            }

            .body-right-box {
                width: 70%;
                margin-left: 2%;
                padding: 30px 20px;
                display: flex;
                justify-content: center;
                align-items: start;
                border: 1px solid #E6E7E8;

                .submit-box {
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    background: #16B82E;
                    color: #ffffff;
                    font-size: 18px;
                    text-align: center;
                    letter-spacing: 3px;
                    border-radius: 3px;
                    cursor: pointer;
                }

                .input-text-box {
                    width: auto;
                    height: 40px;
                    line-height: 40px;
                    padding-left: 10px;
                    display: flex;
                    align-items: center;
                    background: #E8E8E8;
                    margin-bottom: 30px;
                    border-radius: 5px;

                    .input-label{
                        white-space: nowrap;
                        margin-right: 15px;
                    }
                }

                .input-text-textarea {
                    width: auto;
                    min-height: 200px;
                    display: flex;
                    background: #E8E8E8;
                    margin-bottom: 30px;
                    border-radius: 5px;
                    padding-left: 10px;
                    line-height: 30px;

                    .input-label{
                        white-space: nowrap;
                        margin-right: 15px;
                    }
                }
            }
        }
    }

    .news-body {
        width: auto;
        margin: 20px 120px;
        display: flex;



        .news-left {
            width: 25%;
            display: flex;
            flex-direction: column;

            .news-row-box {
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #E5E9F2;

                .left-text {
                    width: calc(100% - 90px);
                    height: 50px;
                    margin-left: 15px;
                    line-height: 50px;
                    font-size: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                    text-align: left;

                }

                .right-icon {
                    width: 30px;
                    height: 30px;
                    border-radius: 30px;
                    margin-right: 15px;
                    margin-left: 15px;
                    font-size: 24px;
                    line-height: 30px;
                    background: #E6E7E8;
                    color: #16B82E;
                    text-align: center;
                }
            }

            .top-name-box {
                width: 100%;
                height: 50px;
                color: #ffffff;
                background: #16B82E;
                letter-spacing: 3px;
                display: flex;
                align-items: center;
            }

            .name-title {
                width: 100%;
                height: 50px;
                padding-left: 30px;
                padding-right: 30px;
                letter-spacing: 3px;
                font-weight: bold;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #ffffff;
                background: #16B82E;
            }
        }

        .news-right {
            width: 70%;
            margin-left: 5%;

            .news-right {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
            }

            .news-body-box {
                width: 100%;
                min-height: 600px;
                border: 1px solid #E7EAED;
                position: relative;

                .body-title-box {
                    width: 100%;
                    padding-top: 20px;
                    display: flex;
                    flex-direction: column;

                    .text-title {
                        width: auto;
                        font-size: 30px;
                        color: #6B7885;
                        font-weight: bold;
                        margin: 0 50px;
                    }


                    .text-body-box {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 20px;
                        position: relative;

                        .text-time {
                            text-align: center;
                        }

                        .text-auther {
                            position: absolute;
                            right: 20px;
                        }
                    }
                }

                .text-body-data {
                    width: auto;
                    margin: 40px;
                    text-align: left;

                    .next-text-box {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 100px;

                        .previous-page {
                            width: 30%;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: normal;
                        }

                        .next-page {
                            width: 30%;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: normal;
                            text-align: right;
                        }
                    }
                }
            }

            .title-box {
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-shadow: 0px 0px 12px rgba(0, 0, 0, .2);
                ;
                border-top: 2px solid #16B82E;

                .body-top-left {
                    width: 40%;
                    color: #16B82E;
                    font-size: 18px;
                    font-weight: bold;
                    margin-left: 15px;
                    text-align: left;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                }

                .body-top-right {
                    width: 35%;
                    margin-right: 15px;
                    color: black;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                }
            }

            .list-box {
                width: 100%;

                .paging-box {
                    width: 100%;
                    margin-top: 60px;
                    margin-bottom: 60px;
                    display: flex;
                    justify-content: center;
                }

                .news-centent-info {
                    width: auto;
                    height: 200px;
                    border-radius: 10px;
                    margin-top: 30px;
                    padding: 10px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    box-shadow: 0px 0px 12px rgba(0, 0, 0, .12);

                    .left-text-box {
                        width: 30%;
                        display: flex;
                        flex-direction: column;
                        text-align: left;
                        position: relative;

                        .activity-title {
                            font-size: 22px;
                            font-weight: bold;
                            margin-bottom: 20px;
                        }

                        .activity-info {
                            display: -webkit-box;
                            -webkit-line-clamp: 5;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: normal;
                        }

                        .activity-time {
                            position: absolute;
                            bottom: 10px;
                            font-size: 12px;
                        }

                    }

                    .right-image-box {
                        width: 65%;
                        height: 100%;
                        margin-left: 5%;
                    }
                }
            }
        }
    }
}

.banner-box {
    width: 100%;
    height: 667px;
}


</style>

<style>
.el-collapse-item .el-icon-arrow-right {
    display: none;
}
</style>