<template>
    <div class="iframe-box">
        <iframe
            id="iframe"
            ref="iframe"
            :src="url"
            :width="scrollWidth"
            :height="scrollHeight"
            frameborder="0"
            allowfullscreen
            scrolling="auto"
            >
        </iframe>
    </div>
</template>

<script>

export default {
    props: {
        url: {
            type: String,
            default: ""
        },
        scrollWidth: {
            type: String,
            default: "100%"
        },
        scrollHeight: {
            type: String,
            default: "1000"
        }
    },
    data() {
        return {

        }
    },
    methods: {

    },

}
</script>

<style>

</style>