<template>
    <div class="article">
        <div class="title-label-box">
            <div :class="['label-box', index == selIdx?'sel-label-box':'']" v-for="(item,index) in labelList" :key="index" @Click="clickLabel(item.id, index)">
                {{ item.name }}
            </div>
        </div>
        <div class="text-body-box">
            <div class="label-url">首页><span @click="pageNum = 1">康养知识文章>{{ labelList[selIdx].name }}</span></div>
            <div v-if="pageNum == 1" class="know-list-box">
                <div class="label-name-high">{{ labelList[selIdx].name }}</div>
                <template v-if="knowList.length > 0">
                    <div class="know-list-row" v-for="(item, index) in knowList" :key="index">
                        <div class="text-title">{{ (index + 1 + (current == 1?0:size * (current - 1) )) + '.' }} {{ item.title }}</div>
                        <div class="text-body" >{{ item.copyTitle  }}</div>
                        <div class="loading-icon" @click="getMapIndex(item.id), knowsMap = item, pageNum = 2">
                            更多
                            <el-icon><DArrowRight /></el-icon>
                        </div>
                    </div>
                </template>
                <div v-if="knowList.length == 0" style="width: 100%; height: 100%;">
                    <img src="../assets/images/无数据.png" style="width: 200px; height: 200px; margin-top: 50px;"/>
                </div>
                <div class="paging-box">
                    <el-pagination :hide-on-single-page="false" :page-size="size" :current-page="current" 
                        :page-sizes="[5,10,30,50,100]" background layout="prev, pager, next, jumper, total, sizes" :total="total" 
                        @current-change="handleCurrentChange" @size-change="handleSizeChange"/>
                </div>
            </div>
            <div v-if="pageNum == 2" class="html-box">
                <div style="font-size: 28px; font-weight: bold; text-align: center; margin-bottom: 30px;">{{ knowsMap.title }}</div>
                <div v-html="knowsMap.content"></div>
                <div class="next-text-box">
                    <div class="previous-page" @click="goNextDoc(1)">
                        <span style="color: #16B82E;">上一篇：</span>
                        <span >
                            {{ openIdx == 0?this.knowList[this.knowList.length - 1].title:this.knowList[openIdx -1].title }}
                        </span>    
                    </div>
                    <div class="next-page" @click="goNextDoc(2)">
                        <span style="color: #16B82E;">下一篇：</span>
                        <span >
                            {{ openIdx == this.knowList.length - 1?this.knowList[0].title:this.knowList[openIdx + 1].title }}
                        </span>    
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api/commonApi'

export default {
    data() {
        return {
            selIdx: 0,
            labelList: [],
            knowList: [],
            current: 1,
            size: 5,
            total: 0,
            knowsMap: {},
            pageNum: 1,
            openIdx: 0,
        }
    },
    created() {
        if(this.$route.params != null && this.$route.params.id) {
            this.pageNum = 2
            this.knowsMap = this.$route.params
        }
        this.getLabelList()
    },
    methods: {
        // 获取标签
        getLabelList() {
            api.libelList({ status: 0 }).then(res => {
                if(res) {
                    this.labelList = res
                    this.getKnowList(res[this.selIdx].id)
                }
            })
        },
        // 获取文章
        getKnowList(labelId) {
            api.knowByLabelId({
                current: this.current,
                size: this.size,
                labelId: labelId,
                type: 1
            }).then(res => {
                if(res) {
                    this.knowList = res.records
                    this.total = res.total
                }
            })
        },
        // 切换标签
        clickLabel(labelId, selIdx) {
            if(this.selIdx != selIdx) {
                this.current = 1
                this.getKnowList(labelId)
                this.selIdx = selIdx
            }
            this.pageNum = 1
        },
        // 切换页数
        handleCurrentChange(e) {
            this.current = e
            this.getKnowList(this.labelList[this.selIdx].id)
        },
        // 切换页数大小
        handleSizeChange(e) {
            this.size = e
            this.getKnowList(this.labelList[this.selIdx].id)
        },
        // 获取上下页标签
        getMapIndex(id) {
            this.knowList.forEach((val,index) => {
                if (val.id == id) {
                    return this.openIdx = index
                }
            });
        },
        // 上/下一篇
        goNextDoc(type) {
            if(type == 1) {
                this.openIdx = this.openIdx == 0?this.knowList.length - 1:this.openIdx -1
                this.knowsMap = this.knowList[this.openIdx]
                
            }else {
                this.openIdx = this.openIdx == this.knowList.length - 1?0:this.openIdx +1
                this.knowsMap = this.knowList[this.openIdx]
            }
        },
    }
}
</script>

<style lang="less">
.article {
    width: auto;
    min-height: 800px;


    .title-label-box {
        width: 64vw;
        height: 60px;
        line-height: 60px;
        margin: 0 18%;
        display: flex;
        align-items: center;
        border-bottom: 2px solid #D2D2D3;
        cursor: pointer;
        overflow-x: scroll;

        .label-box{
            width: auto;
            padding: 0 4%;
            font-size: 18px;
            font-weight: bold;
            white-space: nowrap;
        }
    }

    .text-body-box {
        width: auto;
        margin: 0 18%;
        display: flex;
        flex-direction: column;

        .know-list-box {
            width: 100%;
            min-height: 600px;
            position: relative;
            padding-bottom: 90px;

            .paging-box {
                width: 100%;
                position: absolute;
                bottom: 30px;
                display: flex;
                justify-content: center;
            }

            .label-name-high {
                width: auto;
                padding: 0 10px;
                text-align: left;
                height: 30px;
                font-size: 22px;
                font-weight: bold;
                color: #16B82E;
                border-left: 3px solid #16B82E;
            }

            .know-list-row {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                text-align: left;
                border-bottom: 1px solid #D2D2D3;

                .text-title {
                    font-size: 16px;
                    font-weight: bold;
                    color: #000;
                }

                .text-body {
                    width: 100%;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    text-indent: 2em;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: normal;
                }

                .loading-icon {
                    margin-left: auto;
                    margin-right: 0px;
                    margin-bottom: 15px;
                    color: #16B82E;
                }
            }
        }

    }
}

.html-box {
    width: 100%;
    text-align: left;
}


.sel-label-box{
    border-bottom: 2px solid #16B82E;
}

.label-url{
    font-size: 16px;
    color: #A5A5AA;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: left;
}

.next-text-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 100px;

    .previous-page {
        width: 30%;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
    }

    .next-page {
        width: 30%;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        text-align: right;
    }
}
</style>