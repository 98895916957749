<template>
    <div class="team">
        <!-- 轮播图 -->
        <div class="banner-box">
            <el-carousel height="100%" v-if="imgList.length" autoplay style="width: 100%; height: 100%;" :pause-on-hover="false"
                indicator-position="none">
                <el-carousel-item style="height: 667px" v-for="(item, index) in imgList" :key="index">
                <img :src="item.imgUrl" style="width: 100%; height: 100%;" />
                </el-carousel-item>
            </el-carousel>
        </div>
        <!-- 内容 -->
        <div class="news-body">
            <div class="news-left">
                <el-collapse v-model="activeName" accordion >
                    <el-collapse-item name="1" style="border-bottom: 1px solid #ebeef5;">
                        <template #title>
                            <div class="name-title"  style="background: #088006;" @Click="openNews(1)">
                                <div style="font-size: 18px">专家团队</div>
                                <div style="width: 30px; height: 30px; border-radius: 30px; font-size: 24px; line-height: 30px; text-align: center; background: #ffffff; color: rgba(0,0,0,0.8);">
                                    <el-icon><ArrowRightBold /></el-icon>
                                </div>
                            </div>
                        </template>
                        <div class="news-row-box" :class="{ 'select-back': type == 1 && selIndex == index }" v-for="(item, index) in skillList" :key="index" @click="selIndex = index, type = 1, openInfo = 0">
                            <div class="left-text">{{ item.name }}</div>
                            <div class="right-icon">
                                <el-icon><ArrowRightBold /></el-icon>
                            </div>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="2">
                        <template #title>
                            <div class="name-title" style="background: #088006;" @Click="openNews(2)">
                                <div style="font-size: 18px">志愿者团队</div>
                                <div style="width: 30px; height: 30px; border-radius: 30px; font-size: 24px; line-height: 30px; text-align: center; background: #ffffff; color: rgba(0,0,0,0.8);">
                                    <el-icon><ArrowRightBold /></el-icon>
                                </div>
                            </div>
                        </template>
                        <div class="news-row-box" :class="{ 'select-back': type == 2 && selIndex == index }" v-for="(item, index) in serverList" :key="index" @click="selIndex = index, type = 2, openInfo = 0">
                            <div class="left-text">{{ item.name }}</div>
                            <div class="right-icon">
                                <el-icon><ArrowRightBold /></el-icon>
                            </div>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
            <div class="news-right">
                <div class="title-box">
                    <div class="body-top-left" >{{ type==1?'专家团队':'志愿者团队' }}</div>
                    <div class="body-top-right" >当前位置：首页>{{ type==1?'专家团队':'志愿者团队' }}
                        >{{ selIndex == 0?(type==1?'申请入库专家':'申请成为志愿者'):(selIndex == 1?(type==1?'专家团队':'志愿者团队'):(type == 1?'专家服务风采':'志愿者服务风采')) }}</div>
                </div>
                <!-- 团队列表 -->
                <div v-if="selIndex == 1 && openInfo == 0" class="list-box">
                    <div class="body-title">
                        <div style="width: 35px; height: 35px;"><img src="../assets/images/标题元素.jpg" style="width: 100%; height: 100%;" /></div>
                        <div style="font-size: 28px; font-weight: bold; margin: 0 20px;">{{ type==1?'专家团队-强师赋能':'爱心卓著-感受温暖' }}</div>
                        <div style="width: 35px; height: 35px;"><img src="../assets/images/标题元素.jpg" style="width: 100%; height: 100%; transform: rotate(180deg);" /></div>
                    </div>
                    <el-row :gutter="40" style="width: 100%; height: 100%;">
                        <el-col :span="6" v-for="(item, index) in teamInfoList" :key="index">
                            <div class="news-centent-info"  @Click="goUserInfo(index), infoMap = item, openInfo = 1, useridx = index" >
                                <div class="img-box">
                                    <img :src="item.titleImg" style="width: 100%; height: 100%;" />
                                </div>
                                <div class="name-box">
                                    {{ item.name }}
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <!-- 专家详情 -->
                <div v-if="selIndex == 1 && openInfo == 1" class="team-info-box">
                    <div class="body-title">
                        <div style="width: 35px; height: 35px;"><img src="../assets/images/标题元素.jpg" style="width: 100%; height: 100%;" /></div>
                        <div style="font-size: 28px; font-weight: bold; margin: 0 20px;">{{ type==1?'专家团队-强师赋能':'爱心卓著-感受温暖' }}</div>
                        <div style="width: 35px; height: 35px;"><img src="../assets/images/标题元素.jpg" style="width: 100%; height: 100%; transform: rotate(180deg);" /></div>
                    </div>
                    <div class="info-box">
                        <div class="info-img-box">
                            <div class="left-right-icon" @click="changeInfo(1)">
                                <el-icon style="font-size: 36px; font-weight: bold;"><ArrowLeft /></el-icon>
                            </div>
                            <div class="data-img-box">
                                <img :src="infoMap.titleImg" style="width: 100%; height: 100%;" />
                            </div>
                            <div class="left-right-icon" @click="changeInfo(2)">
                                <el-icon style="font-size: 36px; font-weight: bold;"><ArrowRight /></el-icon>
                            </div>
                        </div>
                        <div class="info-right-box">
                            <div class="info-name-text">{{ infoMap.name }}<span style="margin-left: 20px;">{{ infoMap.phone }}</span></div>
                            <div class="position-box">职务：{{ infoMap.duty }}</div>
                            <div class="info-text">
                                <div class="info-row-text" style="margin-bottom: 20px; display: flex;">
                                    <div style="width: 100px;">个人简介：</div>
                                    <div>{{ infoMap.individualResume }}</div>
                                </div>
                                <div class="info-row-text">单位：{{ infoMap.workUnit }}</div>
                                <div class="info-row-text">技术职称：{{ infoMap.technicalTitles }}</div>
                                <div class="info-row-text">邮箱：{{ infoMap.mail }}</div>
                                <div class="info-row-text">性别：{{ infoMap.gender==1?'男':'女' }}</div>
                                <div class="info-row-text">出生年月：{{ infoMap.birthDate }}</div>
                                <div class="info-row-text">民族：{{ infoMap.nation }}</div>
                                <div class="info-row-text">籍贯：{{ infoMap.hometown }}</div>
                                <div class="info-row-text">政治面貌：{{ infoMap.politicsStatus }}</div>
                                <div class="info-row-text">学历：{{ infoMap.learnLevel }}</div>
                                <div class="info-row-text">学位：{{ infoMap.learnDegree }}</div>
                                <div class="info-row-text">毕业学校：{{ infoMap.school }}</div>
                                <div class="info-row-text">专业：{{ infoMap.major }}</div>
                                <div class="info-row-text">技术方向：{{ infoMap.technologyDirection }}</div>
                                <div class="info-row-text">回避单位：{{ infoMap.avoidWorkUnit }}</div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="body-title">
                        <div style="width: 35px; height: 35px;"><img src="../assets/images/标题元素.jpg" style="width: 100%; height: 100%;" /></div>
                        <div style="font-size: 28px; font-weight: bold; margin: 0 20px;">相关{{ type ==1?'专家':'志愿者' }}</div>
                        <div style="width: 35px; height: 35px;"><img src="../assets/images/标题元素.jpg" style="width: 100%; height: 100%; transform: rotate(180deg);" /></div>
                    </div>
                    <div style="text-align: center;">{{ type == 1?'专业技术导师团':'优秀志愿者' }}</div>
                    <div class="multi-row-box">
                        <el-row :gutter="40" style="width: 100%; height: 100%;">
                            <el-col :span="6" v-for="index in recommendList" :key="index">
                                <div class="news-centent-info"   @Click="goUserInfo(index), useridx = index, infoMap = teamInfoList[index], openInfo = 1" >
                                    <div class="img-box">
                                        <img :src="teamInfoList[index].titleImg" style="width: 100%; height: 100%;" />
                                    </div>
                                    <div class="name-box">
                                        {{ teamInfoList[index].name }}
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <!-- 申请入库 -->
                <div v-if="selIndex == 0" class="news-body-box">
                    <el-form ref="ruleFormRef" :rules="rules" :inline="true" style="max-width: 100%"  :model="form" label-width="auto" class="demo-dynamic">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="姓名" prop="name" >
                                    <el-input v-model="form.name" style="width: 20vw;"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="联系电话" prop="phone">
                                    <el-input v-model="form.phone" maxlength="11"  oninput ="value=value.replace(/[^0-9]/g,'')" style="width: 20vw;"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="个人图片" prop="titleImg">
                                    <el-upload
                                        class="avatar-uploader"
                                        :action="actionUrl"
                                        :headers="headers"
                                        :show-file-list="false"
                                        :on-success="handleAvatarSuccess">
                                        <img v-if="form.titleImg" :src="form.titleImg" class="avatar" />
                                        <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                                    </el-upload>
                                </el-form-item>
                                <el-icon v-if="form.titleImg" style="font-size: 22px;" @click="form.titleImg = ''"><Delete /></el-icon>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="邮箱" prop="mail">
                                    <el-input v-model="form.mail" style="width: 20vw;"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="性别" prop="gender">
                                    <el-radio-group v-model="form.gender" class="ml-4" style="width: 20vw;">
                                        <el-radio :label="1" size="large">男</el-radio>
                                        <el-radio :label="2" size="large">女</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="出生年月" prop="birthDate">
                                    <el-date-picker
                                        v-model="form.birthDate"
                                        type="date"
                                        placeholder="选择预约日期"
                                        value-format="YYYY-MM-DD"
                                        style="width: 20vw;"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="民族" prop="nation">
                                    <el-input v-model="form.nation" style="width: 20vw;"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="政治面貌" prop="politicsStatus">
                                    <el-input v-model="form.politicsStatus" style="width: 20vw;"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="籍贯" prop="hometown">
                                    <el-input v-model="form.hometown" style="width: 20vw;"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="学历" prop="learnLevel">
                                    <el-input v-model="form.learnLevel" style="width: 20vw;"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="学位" prop="learnDegree">
                                    <el-input v-model="form.learnDegree" style="width: 20vw;"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="毕业学校" prop="school">
                                    <el-input v-model="form.school" style="width: 20vw;" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="所学专业" prop="major">
                                    <el-input v-model="form.major" style="width: 20vw;"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="工作单位" prop="workUnit">
                                    <el-input v-model="form.workUnit" style="width: 20vw;"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="职务" prop="duty">
                                    <el-input v-model="form.duty" style="width: 20vw;"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="技术职称" prop="technicalTitles">
                                    <el-input v-model="form.technicalTitles" style="width: 50.5vw"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="技术方向" prop="technologyDirection">
                                    <el-input v-model="form.technologyDirection" style="width: 50.5vw"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="回避单位" prop="avoidWorkUnit">
                                    <el-input v-model="form.avoidWorkUnit" style="width: 50.5vw"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="个人简介" prop="individualResume">
                                    <el-input :rows="5" type="textarea" v-model="form.individualResume" style="width: 50.5vw"/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <div class="submit-bootom">
                            <el-button type="success" class="submit-bootom-box" @click="submitFunc('ruleFormRef')">提交申请</el-button>
                        </div>
                    </el-form>
                </div>
                <!-- 风采模块 -->
                <div v-if="selIndex == 2 && openInfo == 0" class="list-box" >
                    <el-row :gutter="40" style="width: 100%; height: 100%;">
                        <el-col :span="6" v-for="(item, index) in teamMienList" :key="index">
                            <div class="news-centent-info"  @Click="goUserInfo(index), infoMap = item, openInfo = 1, useridx = index" >
                                <div class="img-box">
                                    <img :src="item.imgUrl" style="width: 100%; height: 100%;" />
                                </div>
                                <div class="name-box">
                                    {{ item.title }}
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <!-- 风采模块详情 -->
                <div v-if="selIndex == 2 && openInfo == 1" class="html-box">
                    <div style="font-size: 28px; font-weight: bold; text-align: center; margin-bottom: 30px;">{{ infoMap.title }}</div>
                    <div v-html="infoMap.copyTitle"></div>
                    <div class="next-text-box">
                        <div class="previous-page" @click="goNextDoc(1)">
                            <span style="color: #16B82E;">上一篇：</span>
                            <span >
                                {{ useridx == 0?this.teamMienList[this.teamMienList.length - 1].title:this.teamMienList[useridx -1].title }}
                            </span>    
                        </div>
                        <div class="next-page" @click="goNextDoc(2)">
                            <span style="color: #16B82E;">下一篇：</span>
                            <span >
                                {{ useridx == this.teamMienList.length - 1?this.teamMienList[0].title:this.teamMienList[useridx + 1].title }}
                            </span>    
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { ElMessage } from 'element-plus';
import api from '@/api/commonApi'



export default {
    data() {
        return {
            actionUrl: process.env.VUE_APP_SERVER_URL + "/api/v1/product/file/upload",
            headers: {
                source: "WebSite"
            },
            imgList: [],
            // 专家团队栏目
            skillList: [
                {id: 1, type: 1, name: '申请成为专家'},
                {id: 2, type: 1, name: '专家团队'},
                {id: 3, type: 1, name: '专家服务风采'},
            ],
            // 志愿者团队栏目
            serverList: [
                {id: 1, type: 2, name: '申请成为志愿者'},
                {id: 2, type: 2, name: '志愿者团队'},
                {id: 3, type: 2, name: '志愿者服务风采'},
            ],
            // 专家/志愿者数据
            teamInfoList: [],
            // 风采数据
            teamMienList: [],
            // 推荐相关
            recommendList: [],
            // 当前用户下标
            useridx: 0,
            // 类型 1-专家，2-志愿者
            type: 1,
            // 选择下标
            selIndex: 1,
            // 手风琴下标
            activityName: '1',
            // 专家详情
            openInfo: 0,
            // 专家信息
            infoMap: {},
            // 表单
            form: {
                gender: 1
            },
            // 表单校验
            rules:  {
                name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
                titleImg: [{ required: true, message: '请上传头像', trigger: 'blur' }],
                phone: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
                birthDate: [{ required: true, message: '请选择出生日期', trigger: 'blur' }],
                nation: [{ required: true, message: '请输入民族', trigger: 'blur' }],
                politicsStatus: [{ required: true, message: '请输入政治面貌', trigger: 'blur' }],
                hometown: [{ required: true, message: '请填写籍贯', trigger: 'blur' }],
                learnLevel: [{ required: true, message: '请填写学历', trigger: 'blur' }],
                learnDegree: [{ required: true, message: '请填写学位', trigger: 'blur' }]
            },
            // 申请提交节流
            isSubmit: true,
        }
    },
    watch: {
        '$route.query.type': function (newId, oldId) {
            console.log(newId, oldId);
            if (newId != oldId) {
                this.type = newId
                this.activeName = newId + ''

            }
        }
    },
    created() {
        let val = this.$route.query
        if (val) {
            if (val.type) {
                this.type = val.type
                this.activeName = val.type
                this.getTeamList(val.type)
                this.getTeamMienList(val.type)
            }else {
                this.getTeamList(1)
                this.getTeamMienList(1)
            }
        }
        this.getBannerImgs()
    },
    methods: {
        // 获取轮播图
        getBannerImgs() {
            api.bannerList({ status: 0 }).then(res => {
                if (res) {
                    this.imgList = res
                }
            })
        },
        // 获取团队
        getTeamList(type) {
            api.teamInfoList({status: 1, viewState: 0, type: type}).then(res => {
                if(res) {
                    this.teamInfoList = res
                }
            })
        },
        // 获取风采
        getTeamMienList(type) {
            api.teamMienList({type: type}).then(res => {
                if(res) {
                    this.teamMienList = res
                }
            })
        },
        // 打开手风琴
        openNews(type) {
            if (this.activeName != null && this.type != type) {
                this.type = type
                this.getTeamList(type)
                this.getTeamMienList(type)
            }
        },
        // 提交申请
        submitFunc() {
            this.$refs['ruleFormRef'].validate((valid) => {
                if(valid) {
                    if(this.isSubmit){
                        this.isSubmit = false
                        this.form.type = this.type
                        api.teamInfoSave(this.form).then(res => {
                            if(res) {
                                ElMessage.success("已提交申请")
                                this.form = {
                                    gender: 1
                                }
                                this.isSubmit = true
                            }
                        })
                        setTimeout(() => {
                            this.isSubmit = true
                        }, 3000);
                    }
                }
            })
        },
        // 文件上传返回信息
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if(response.data) {
                this.form.titleImg = response.data.url
            }
        },
        // 头像预览
        handleRemove(file) {
            console.log(file);
        },
        // 点击进入详情
        goUserInfo(index) {
            // 随机4个
            this.recommendList = [];
            if(this.teamInfoList.length > 4) {
                while (this.recommendList.length < 4) {
                    var randomIndex = Math.floor(Math.random() * this.teamInfoList.length);
                    if (randomIndex != index && this.recommendList.includes(randomIndex) == false) {
                        this.recommendList.push(randomIndex);
                    }
                }
            }else{
                this.teamInfoList.forEach((val,index) => {
                    this.recommendList.push(index)
                });
            }
        },
        // 切换详情信息
        changeInfo(type) {
            if(type == 1) {
                this.useridx = this.useridx == 0?this.teamInfoList.length -1:this.useridx-1
                this.infoMap = this.teamInfoList[this.useridx]
                this.goUserInfo(this.useridx)
            }else {
                this.useridx = this.useridx == this.teamInfoList.length -1?0:this.useridx+1
                this.infoMap = this.teamInfoList[this.useridx]
                this.goUserInfo(this.useridx)
            }
        },
        // 切换信息
        goNextDoc(type) {
            if(type == 1) {
                this.useridx = this.useridx == 0?this.teamMienList.length -1:this.useridx-1
                this.infoMap = this.teamMienList[this.useridx]
            }else {
                this.useridx = this.useridx == this.teamMienList.length -1?0:this.useridx+1
                this.infoMap = this.teamMienList[this.useridx]
            }
        }
    }
}
</script>

<style lang="less" >
.team {
    width: 100%;
    margin-top: -10px;

    .banner-box {
        width: 100%;
        height: 667px;
    }

    .news-body {
        width: auto;
        margin: 20px 120px;
        display: flex;



        .news-left {
            width: 25%;
            display: flex;
            flex-direction: column;

            .news-row-box {
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #E5E9F2;

                .left-text{
                    width: calc(100% - 90px);
                    height: 50px;
                    margin-left: 15px;
                    line-height: 50px;
                    font-size: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                    text-align: left;

                }

                .right-icon {
                    width: 30px; 
                    height: 30px; 
                    border-radius: 30px; 
                    margin-right: 15px;
                    margin-left: 15px;
                    font-size: 24px; 
                    line-height: 30px; 
                    background: #E6E7E8; 
                    color: #16B82E;
                    text-align: center;
                }
            }

            .top-name-box{
                width: 100%;
                height: 50px;
                color: #ffffff;
                background: #16B82E;
                letter-spacing: 3px;
                display: flex;
                align-items: center;
            }

            .name-title {
                width: 100%;
                height: 50px;
                padding-left: 30px;
                padding-right: 30px;
                letter-spacing: 3px;
                font-weight: bold;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #ffffff;
                background: #16B82E;
            }
        }

        .news-right {
            width: 70%;
            margin-left: 5%;

            .news-body-box {
                width: 100%;
                min-height: 600px;
                position: relative;
                padding-top: 50px;


                .submit-bootom{
                    width: 100%;
                    margin-top: 60px;
                    display: flex;
                    justify-content: center;

                    .submit-bootom-box{
                        width: 180px;
                        height: 60px;
                        line-height: 60px;
                        font-size: 28px;
                        font-weight: bold;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }

            .title-box {
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-shadow: 0px 0px 12px rgba(0, 0, 0, .2);;
                border-top: 2px solid #16B82E;

                .body-top-left {
                    width: 40%;
                    color: #16B82E; 
                    font-size: 18px; 
                    font-weight: bold; 
                    margin-left: 15px;
                    text-align: left;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                }

                .body-top-right {
                    width: 35%;
                    margin-right: 15px; 
                    color: black;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                }
            }

            .list-box {
                width: 100%;
                min-height: 400px;

                .body-title {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    flex-direction: row;
                    align-items: center;
                    margin-top: 50px;
                    margin-bottom: 20px;
                }

                .news-centent-info {
                    width: auto;
                    min-height: 300px;
                    margin-top: 30px;

                    .img-box {
                        width: 100%;
                        height: 250px;
                    }

                    .name-box {
                        width: 100%;
                        height: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: #ECF1F1;
                    }
                }
            }

            .team-info-box {
                width: 100%;
                min-height: 400px;

                .body-title {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    flex-direction: row;
                    align-items: center;
                    margin-top: 50px;
                    margin-bottom: 20px;
                }

                .multi-row-box {
                    width: 100%;
                    min-height: 400px;

                    .body-title {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        flex-direction: row;
                        align-items: center;
                        margin-top: 50px;
                        margin-bottom: 20px;
                    }

                    .news-centent-info {
                        width: auto;
                        min-height: 300px;
                        margin-top: 30px;

                        .img-box {
                            width: 100%;
                            height: 250px;
                        }

                        .name-box {
                            width: 100%;
                            height: 50px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: #ECF1F1;
                        }
                    }
                }

                .info-box {
                    width: 100%;
                    min-height: 260px;
                    margin-top: 30px;
                    padding: 20px 0;
                    display: flex;
                    flex-direction: row;
                    border: 1px solid #E5E9F2;

                    .info-img-box{
                        width: 35%;
                        height: 340px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .left-right-icon{
                            width: 60px;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #16B82E;
                        }

                        .data-img-box {
                            width: auto;
                            height: 100%;
                            flex-grow: 1;
                        }
                    }

                    .info-right-box {
                        width: 64%;
                        min-height: 260px;
                        margin-left: 1%;

                        .info-name-text {
                            font-size: 36px;
                            font-weight: bold;
                            color: #000000;
                        }

                        .position-box {
                            width: auto;
                            height: 60px;
                            margin-top: 10px;
                            margin-bottom: 20px;
                            display: flex;
                            padding: 0 30px;
                            font-size: 22px;
                            font-weight: 500;
                            align-items: center;
                            background: #E5E9F2;
                            border-bottom: 2px solid #C3C4C5;
                        }

                        .info-text {
                            width: auto;
                            height: 100%;
                            padding: 0 20px;
                            display: flex;
                            flex-direction: column;

                            .info-row-text {
                                margin-top: 10px;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }
    }
}

.el-collapse-item .el-icon-arrow-right {
    display: none;
}

.select-back {
    background: #EBEBEB;
}

</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #dcdfe6;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: .2s;
}

.avatar-uploader .el-upload:hover {
  border-color: blue;
}

.avatar {
    width: 178px;
    height: 178px;
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.html-box {
    width: 100%;
    text-align: left;

    .next-text-box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 100px;

        .previous-page {
            width: 30%;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
        }

        .next-page {
            width: 30%;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            text-align: right;
        }
    }
}

.el-collapse-item__wrap {
    border-bottom: none;
}
</style>