<template>
    <div class="news">
        <!-- 轮播图 -->
        <div class="banner-box">
            <el-carousel height="100%" v-if="imgList.length" autoplay style="width: 100%; height: 100%;" :pause-on-hover="false"
                indicator-position="none">
                <el-carousel-item style="height: 667px" v-for="(item, index) in imgList" :key="index">
                <img :src="item.imgUrl" style="width: 100%; height: 100%;" />
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="news-body">
            <div class="news-left">
                <el-collapse v-model="activeName" accordion @change="handleChange">
                    <el-collapse-item name="1" style="border-bottom: 1px solid #ebeef5;">
                        <template #title>
                            <div class="name-title"  style="background: #088006;" @Click="openNews(1), viewType = item.enteringType">
                                <div style="font-size: 18px">行业新闻</div>
                                <div style="width: 30px; height: 30px; border-radius: 30px; font-size: 24px; line-height: 30px; text-align: center; background: #ffffff; color: rgba(0,0,0,0.8);">
                                    <el-icon><ArrowRightBold /></el-icon>
                                </div>
                            </div>
                        </template>
                        <div class="news-row-box" v-for="(item, index) in allNewsList" :key="index" @click="getNewsInfo(item.id), viewType = item.enteringType">
                            <div class="left-text">{{ item.title }}</div>
                            <div class="right-icon">
                                <el-icon><ArrowRightBold /></el-icon>
                            </div>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="2">
                        <template #title>
                            <div class="name-title" style="background: #088006;" @Click="openNews(2)">
                                <div style="font-size: 18px">省内新闻</div>
                                <div style="width: 30px; height: 30px; border-radius: 30px; font-size: 24px; line-height: 30px; text-align: center; background: #ffffff; color: rgba(0,0,0,0.8);">
                                    <el-icon><ArrowRightBold /></el-icon>
                                </div>
                            </div>
                        </template>
                        <div class="news-row-box" v-for="(item, index) in allNewsList" :key="index" @click="getNewsInfo(item.id)">
                            <div class="left-text">{{ item.title }}</div>
                            <div class="right-icon">
                                <el-icon><ArrowRightBold /></el-icon>
                            </div>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
            <div class="news-right">
                <div class="title-box">
                    <div class="body-top-left" >{{ newsId != null || viewType==1 ?newsMap['title']:'新闻中心' }}</div>
                    <div class="body-top-right" >当前位置：首页>
                        <text @click="openNews(type), viewType = 0">新闻中心</text>
                        >{{ newsId != null || viewType==1 ?newsMap['title']:(type == '1'?'行业新闻':'省内新闻') }}</div>
                </div>
                <!-- 列表 -->
                <div v-if="viewType == 0" class="list-box">
                    <div class="news-centent-info" v-for="(item, index) in newsList" :key="index"  @Click="getNewsInfo(item.id)" style="cursor:pointer;">
                        <div style="margin-top: 5px; "><el-icon>
                            <CaretRight style="color: #16B82E; font-size: 18px;" />
                            </el-icon></div>
                        <div class="title-name-box">{{ item.title + item.title + item.title }}</div>
                        <div style="width: 100px; margin-right: 10px;">{{ item.createTime }}</div>
                    </div>
                    <div class="paging-box">
                        <el-pagination :hide-on-single-page="false" :page-size="size" :current-page="current" 
                            :page-sizes="[5,10,30,50,100]" background layout="prev, pager, next, jumper, total, sizes" :total="total" 
                            @current-change="handleCurrentChange" @size-change="handleSizeChange"/>
                    </div>
                </div>
                <!-- 手工 -->
                <div v-if="viewType == 1" class="news-body-box">
                    <div class="body-title-box">
                        <div class="text-title">{{ newsMap.title }}</div>
                        <div class="text-body-box">
                            <div class="text-time">时间：{{ newsMap.createTime }}</div>
                            <div class="text-auther">作者：{{ newsMap.author }}</div>
                        </div>
                    </div>
                    <div style="margin-top: 25px; width: auto; height: 1px; margin: 0 20px; border-bottom: 1px dashed #666;"></div>
                    <div class="text-body-data">
                        <div v-html="newsMap.content"></div>
                        <div class="next-text-box">
                            <div class="previous-page"  @click="goNextDoc(1)">
                                <span style="color: #16B82E;">上一篇：</span>
                                <span >
                                    {{ openIdx == 0?this.allNewsList[this.allNewsList.length - 1].title:this.allNewsList[openIdx -1].title }}
                                </span>    
                            </div>
                            <div class="next-page"  @click="goNextDoc(2)">
                                <span style="color: #16B82E;">下一篇：</span>
                                <span >
                                    {{ openIdx == this.allNewsList.length - 1?this.allNewsList[0].title:this.allNewsList[openIdx + 1].title }}
                                </span>    
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 链接 -->
                <div v-if="viewType == 2" class="news-body-box" ref="iframeRef">
                    <UrlPage :url="newsMap.content" :scrollWidth="scrollWidth" :scrollwHeight="scrollHeight" ></UrlPage>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api/commonApi'
import UrlPage from '@/components/UrlPage.vue'

export default {
    components: {
        UrlPage
    },
    data() {
        return {
            type: 1,
            newsId: null,
            viewType: 0,
            newsMap: {},
            openIdx: 0,
            activeName: '1',
            imgList: [],
            newsList: [],
            allNewsList: [],
            current: 1,
            size: 10,
            total: 0,
            scrollWidth: '100%',
            scrollHeight: '600',
        }
    },
    created() {
        let val = this.$route.query
        if(val){
            if(val.type){
                this.type = val.type
                this.activeName = val.type
            }
            if(val.id) {
                this.newsId = val.id
                this.getNewsInfo(val.id)
            }
        }
        this.getBannerImgs()
        this.getNewsList(this.$route.query)
        this.getAllNewsList()
    },
    watch: {
        '$route.query.type': function (newId, oldId){
                console.log(newId, oldId);
                if (newId != oldId) {
                    this.type = newId
                    this.activeName = newId + ''
                    this.getAllNewsList()
                    this.getNewsList({type: newId})
                }
            }    
    },
    methods: {
        // 获取列表数据
        getNewsList(val) {
            api.newsList({ current: this.current, size: this.size, status: 1, showState: 0, type: val.type?val.type:1}).then(res => {
                if (res) {
                    this.newsList = res.records
                    this.total = res.total
                }
            })
        },
        // 获取详情
        getNewsInfo(id) {
            api.newsInfo(id).then(res => {
                if(res) {
                    this.viewType = res.enteringType
                    if(res.enteringType == 2) {
                        // console.log(this.$refs.iframeRef.offsetWidth, this.$refs.iframeRef.offsetHeight );
                        // this.scrollWidth = this.$refs.iframeRef.offsetWidth
                        // this.scrollHeight = this.$refs.iframeRef.offsetHeight
                    }
                    this.newsMap = res
                    this.allNewsList.forEach((val,index) => {
                        if(val.id == res.id) {
                            return this.openIdx = index
                        }
                    });
                }
            })
        },
        // 获取轮播图
        getBannerImgs() {
            api.bannerList({ status: 0 }).then(res => {
                if (res) {
                this.imgList = res
                }
            })
        },
        // 开关手风琴
        handleChange(e) {
            this.activeName = e
        },
        getAllNewsList() {
            let val = this.$route.query
            api.newsList({current: this.current, size: this.size, status: 1, showState: 0, type: val.type?val.type:1}).then(res => {
                if(res) {
                    this.allNewsList = res.records
                    this.total = res.total
                }
            })
        },
        // 打开手风琴
        openNews(type) {
            if(this.activeName != null){
                this.type = type
                api.newsList({current: this.current, size: this.size, status: 1, showState: 0, type: type}).then(res => {
                    if(res) {
                        this.allNewsList = res.records
                        this.total = res.total
                    }
                })
                this.getNewsList({type: type})
            }
        },
        // 切换页数
        handleCurrentChange(e) {
            this.current = e
            this.getNewsList({type: this.type})
        },
        // 切换页数大小
        handleSizeChange(e) {
            this.size = e
            this.getNewsList({type: this.type})
        },
        // 上/下一篇
        goNextDoc(type) {
            if(type == 1) {
                this.openIdx = this.openIdx == 0?this.allNewsList.length - 1:this.openIdx -1
                this.newsMap = this.allNewsList[this.openIdx]
                this.viewType = this.allNewsList[this.openIdx].enteringType
                
            }else {
                
                this.openIdx = this.openIdx == this.allNewsList.length - 1?0:this.openIdx +1
                this.newsMap = this.allNewsList[this.openIdx]
                this.viewType = this.allNewsList[this.openIdx].enteringType
            }
        },
    }
}

</script>

<style lang="less" scoped>
.news {
    width: 100%;
    margin-top: -10px;

    .news-body {
        width: auto;
        margin: 20px 120px;
        display: flex;



        .news-left {
            width: 25%;
            display: flex;
            flex-direction: column;

            .news-row-box {
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #E5E9F2;

                .left-text{
                    width: calc(100% - 90px);
                    height: 50px;
                    margin-left: 15px;
                    line-height: 50px;
                    font-size: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                    text-align: left;

                }

                .right-icon {
                    width: 30px; 
                    height: 30px; 
                    border-radius: 30px; 
                    margin-right: 15px;
                    margin-left: 15px;
                    font-size: 24px; 
                    line-height: 30px; 
                    background: #E6E7E8; 
                    color: #16B82E;
                    text-align: center;
                }
            }

            .top-name-box{
                width: 100%;
                height: 50px;
                color: #ffffff;
                background: #16B82E;
                letter-spacing: 3px;
                display: flex;
                align-items: center;
            }

            .name-title {
                width: 100%;
                height: 50px;
                padding-left: 30px;
                padding-right: 30px;
                letter-spacing: 3px;
                font-weight: bold;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #ffffff;
                background: #16B82E;
            }
        }

        .news-right {
            width: 70%;
            margin-left: 5%;

            .news-body-box {
                width: 100%;
                min-height: 600px;
                border: 1px solid #E7EAED;
                position: relative;

                .body-title-box {
                    width: 100%;
                    padding-top: 20px;
                    display: flex;
                    flex-direction: column;

                    .text-title {
                        width: auto;
                        font-size: 30px;
                        color: #6B7885;
                        font-weight: bold;
                        margin: 0 50px;
                    }


                    .text-body-box {
                        width: 100%;
                        display: flex; 
                        justify-content: center;
                        align-items: center; 
                        margin-top: 20px;
                        position: relative;

                        .text-time {
                            text-align: center;
                        }

                        .text-auther {
                            position: absolute;
                            right: 20px;
                        }
                    }
                }

                .text-body-data {
                    width: auto;
                    margin: 40px;
                    text-align: left;

                    .next-text-box {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 100px;

                        .previous-page {
                            width: 30%;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: normal;
                        }

                        .next-page {
                            width: 30%;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: normal;
                            text-align: right;
                        }
                    }
                }
            }

            .title-box {
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-shadow: 0px 0px 12px rgba(0, 0, 0, .2);;
                border-top: 2px solid #16B82E;

                .body-top-left {
                    width: 40%;
                    color: #16B82E; 
                    font-size: 18px; 
                    font-weight: bold; 
                    margin-left: 15px;
                    text-align: left;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                }

                .body-top-right {
                    width: 35%;
                    margin-right: 15px; 
                    color: black;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                }
            }

            .list-box {
                width: 100%;
                border: 1px solid #E7EAED;

                .paging-box {
                    width: 100%;
                    margin-top: 60px;
                    margin-bottom: 60px;
                    display: flex;
                    justify-content: center;
                }

                .news-centent-info {
                    width: auto;
                    height: 60px;
                    padding-left: 10px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    border-bottom: 1px solid #E7EAED;

                    .title-name-box {
                        width: calc(100% - 25px - 110px - 40px);
                        flex-grow: 1;
                        text-align: left;
                        margin-left: 10px;
                        margin-right: 15px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        display: block;
                    }
                }
            }
        }
    }
}

.banner-box {
  width: 100%;
  height: 667px;
}


</style>

<style>
    .el-collapse-item .el-icon-arrow-right {
        display: none;
    }
</style>